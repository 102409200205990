const embedMediaplatformPlayer = ((appConfig, anywhereId) => {
    const isSafari = (navigator.userAgent.indexOf('Safari') > -1);

    var playerElem = 'mediaplatformPlayer';
    kWidget.embed({
        'targetId': playerElem,
        'wid': '_' + appConfig.partnerId,
        'uiconf_id': appConfig.uiConf,
        'flashvars': {
            'streamerType': 'auto',
            //'fullScreenBtn.order': 1,
            'fullScreenBtn.parent': 'controlBarContainer',
            'playPauseBtn.parent': 'controlBarContainer',
            'scrubber.parent': 'controlsContainer',
            'volumeControl.showSlider': false,
            'currentTimeLabel.order': 0,
            'durationLabel.order': 1,
            'scrubber.order': 2,
            //'chromecast.order': 2,
            //'volumeControl.order': 3,
            // "ks": ks
            //"debuggerAMG.plugin": true
            'IframeCustomPluginCss1': 'styles/video-player.css'
        },
        'captureClickEventForiOS': true,
        'entry_id': anywhereId,
    });

    kWidget.addReadyCallback((playerId) => {
        const kdp = document.getElementById(playerId);
        if (kdp.kBind) {
            kdp.kBind('mediaReady', ((res) => {
                trackVideoEvents(kdp, elemData);
                if (!isSafari) {
                    kdp.sendNotification('doPlay');
                }
            }));
        }
    });
});

const generateMediaplatformVideoUrl = ((appConfig) => {
    return appConfig.mediaplatformVideoUrl
        .split(':partner_id:').join(appConfig.partnerId)
        .replace(':uiConf:', appConfig.uiConf);
});

let initMediaplatformTimeout;
const initMediaplatform = ((anywhereId, mediaplatformId) => {
    const appConfigPromise = httpGet(appConfigUrl)
        .then((appConfig) => {
            const mediaplatformUrl = generateMediaplatformVideoUrl(appConfig.mediaplatform);

            const mediaplatformUrlPromise = httpGet(mediaplatformUrl)
                .then((mediaplatformScript) => {
                    if (typeof (kWidget) === 'undefined') {
                        if (typeof (initMediaplatformTimeout) === 'undefined') {
                            const firstScriptTag = document.getElementsByTagName('script')[0];
                            const mediaplatformScript = document.createElement('script');

                            mediaplatformScript.src = mediaplatformUrl;

                            firstScriptTag.parentNode.insertBefore(mediaplatformScript, firstScriptTag);
                        }

                        initMediaplatformTimeout = setTimeout(() => {
                            initMediaplatform(anywhereId, mediaplatformId);
                        }, 333);
                    } else {
                        embedMediaplatformPlayer(appConfig.mediaplatform, mediaplatformId);
                    }
                });
        });
});
